body {
    --main-color: #f65008;
    --secondary-color: #0a303a;
}

.bg-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

a.navigation-links::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 35px;
    width: 95%;
    -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
    box-shadow: 0px 5px 12.09px 0.91px rgba(71, 51, 127, 0.11);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    top: 31px;
    margin: 0 auto;
    background: var(--main-color);
    border-radius: 10px 10px 20px 20px;
    opacity: 0;
    z-index: -1;
}
a.navigation-links,
a.navigation-links {
    color: #fff;
}
a.navigation-links.active::before,
a.navigation-links.active::before {
    opacity: 1;
}

a.active.navigation-links {
    color: white;
}

.active-mob {
    display: block !important;
    width: 100%;
    margin-left: 20px !important ;
}

.second-language {
    font-weight: 800;
    /* color: var(--secondary-color) !important; */
    /* font-size: 18px; */
    color: #676666;
    font-size: 16px;
    font-weight: 500;
}

.img-loading {
    background: linear-gradient(90deg, #e0e0e0 25%, #f4f4f4 50%, #e0e0e0 75%);
    animation: shimmer 1.5s infinite;
    background-size: 200% 100%;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
